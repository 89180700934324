<template>
  <div id="simulation-view" class="mx-auto w-[696px]">
    <BaseCard class="flex w-full flex-col gap-4 p-6">
      <TextTitle>Jouw simulaties</TextTitle>
      <div class="flex justify-between gap-4">
        <InputField
          v-model="query"
          placeholder="Zoek een simulatie"
          data-cy="search-simulations"
          icon="search"
          class="w-1/2"
        />
        <div class="flex gap-4">
          <BaseButton
            color="secondary"
            square
            @click="() => refreshList(true, false)"
          >
            <BaseIcon name="refresh" class="text-xl" />
          </BaseButton>
          <RouterLink data-cy="new-simulation" :to="{ name: 'wizard' }">
            <component
              :is="demoStore.simulation_quotum_exceeded ? BaseTooltip : 'div'"
              description="Maximaal aantal simulaties voor deze demo bereikt"
            >
              <BaseButton :disabled="demoStore.simulation_quotum_exceeded"
                >Nieuwe simulatie
              </BaseButton>
            </component>
          </RouterLink>
        </div>
      </div>
      <!-- Simulation cards -->
      <LoadingWrapper
        :loading="loading"
        :error="simulationsStore.loadingFailed"
      >
        <SimulationsViewItem
          v-for="(simulation, index) in simulations"
          :key="simulation.simulation_token"
          :item-number="index"
          :simulation
          @duplicate="duplicateSimulation"
          @delete="deleteSimulation"
          @edit-name="changeSimulationName"
        />
      </LoadingWrapper>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue"
import { useNotificationStore } from "@/stores/useNotificationStore"
import useSimulationsStore from "@/stores/simulationsStore.js"
import {
  TextTitle,
  BaseCard,
  BaseButton,
  BaseIcon,
  LoadingWrapper,
  InputField,
  BaseTooltip,
} from "@repowerednl/ui-component-library"
import SimulationsViewItem from "@/components/SimulationsViewItem.vue"
import { useDemoStore } from "@/stores/useDemoStore.js"

const demoStore = useDemoStore()
const notificationStore = useNotificationStore()
const simulationsStore = useSimulationsStore()
const query = ref("")
const loading = ref(false)
const REFRESH_INTERVAL = 1000 * 60 * 2 // 2 minutes
let timer = null

const simulations = computed(() => {
  return simulationsStore.simulations
    .filter((sim) =>
      sim.case_name.toLowerCase().includes(query.value.toLowerCase()),
    )
    .sort(
      (a, b) =>
        new Date(b.date_modified ?? b.date_created) -
        new Date(a.date_modified ?? a.date_created),
    )
})

function duplicateSimulation(simulationToken) {
  loading.value = true
  simulationsStore.duplicate(
    simulationToken,
    () => {
      loading.value = false
      notificationStore.pushToast("Simulatie gedupliceerd")
    },
    (error) => {
      loading.value = false
      notificationStore.pushError(
        "Dupliceren van simulatie mislukt",
        `Probeer het later opnieuw (code: ${error.code}).`,
        "duplication-failed",
      )
    },
  )
}

function deleteSimulation(simulationToken) {
  loading.value = true
  simulationsStore.delete(
    simulationToken,
    () => {
      loading.value = false
      notificationStore.pushToast("Simulatie verwijderd")
    },
    (error) => {
      loading.value = false
      notificationStore.pushError(
        "Verwijderen van simulatie mislukt",
        `Probeer het later opnieuw (code: ${error.code}).`,
        "deletion-failed",
      )
    },
  )
}

function changeSimulationName(params) {
  const simToken = params[0]
  const newName = params[1]
  simulationsStore.changeCaseName(
    simToken,
    newName,
    () => {
      notificationStore.pushToast(
        `Simulatie naam gewijzigd`,
        `${newName}`,
        "name-changed",
      )
    },
    (error) => {
      notificationStore.pushError(
        "Naam wijzigen mislukt",
        `Probeer het later opnieuw (code: ${error.code}).`,
        "name-change-failed",
      )
    },
  )
}
/**
 * Refresh the list of simulations. Optionally show a loading icon. Use 'loop'
 * to refresh the list automatically every refresh interval.
 */
function refreshList(showLoading, loop) {
  if (showLoading) loading.value = true
  simulationsStore.loadSimulations(() => {
    loading.value = false
    if (loop) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        refreshList(false, true)
      }, REFRESH_INTERVAL)
    }
  })
}

onMounted(() => {
  refreshList(true, true)
})

onUnmounted(() => {
  clearTimeout(timer)
})
</script>
