<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 3"
    title="Eigen data uploaden"
    :description="description"
    :is-active="isActive"
    :is-enabled="isEnabled"
    :is-complete="!wizardValidationStore.dataStepInvalid"
    :validation-state="wizardValidationStore.v$dataRules"
    @confirm-btn="wizardValidationStore.v$dataRules.$validate()"
  >
    <template #content>
      <CheckboxItem v-model="wizardStore.useExcelData" data-cy="use-excel-data">
        Eigen data aanleveren met een Excelbestand
      </CheckboxItem>
      <div
        v-if="!wizardStore.useExcelData"
        class="rounded-lg bg-primary-50 p-4"
      >
        Je hebt aangegeven geen data aan te leveren via een Excelbestand. Geef
        je verbruiks- en/of opwekdata op in de volgende stap(pen).
      </div>
      <template v-else>
        <div class="flex flex-col">
          <p class="font-medium">1. Download onze template</p>
          <p class="text-gray-400">Download de template en vul je data in</p>
        </div>
        <div class="flex gap-4">
          <BaseButton
            class="border border-primary"
            color="minimal-primary"
            @click="templateHandler"
          >
            <BaseIcon name="download-2" class="mr-2" :filled="false" />
            Download de Excel template
          </BaseButton>
          <BaseDrawer>
            <template #trigger>
              <BaseButton color="secondary">Uitleg template</BaseButton>
            </template>
            <template #content>
              <div class="border-b border-gray-100 p-6">
                <TextDrawerTitle>
                  Bestandsindeling verbruiksdata
                </TextDrawerTitle>
              </div>
              <div class="documentation space-y-2 p-6">
                <p>
                  De verbruiksdata moet in een Excel bestand staan met de juiste
                  indeling. Gebruik het template om de data op de juist wijze in
                  te vullen.
                </p>
                <h2>Soorten data</h2>
                <p>Er zijn verschillende manieren om data aan te leveren:</p>
                <ol>
                  <li>
                    <h3>Alleen verbruik</h3>
                    <h4>Kolommen: LDN</h4>
                    <p>Alleen het verbruik van de aansluiting.</p>
                  </li>
                  <li>
                    <h3>Alleen bruto opwek</h3>
                    <h4>Kolommen: BPM</h4>
                    <p>Alleen de opwek van de bruto productie meter.</p>
                  </li>
                  <li>
                    <h3>Verbruik en opwek</h3>
                    <h4>Kolommen: LDN, ODN</h4>
                    <p>
                      Het verbruik van de aansluiting en de opwek zonder opwek
                      van de bruto productie meter.
                    </p>
                  </li>
                  <li>
                    <h3>Verbruik, opwek en bruto productie</h3>
                    <h4>Kolommen: LDN, ODN, BPM</h4>
                    <p>
                      Het verbruik van de aansluiting, de opwek en de opwek van
                      de bruto productie meter.
                    </p>
                  </li>
                </ol>
                <h2>Uitleg energiekolommen</h2>
                <ul>
                  <li>
                    <h3>LDN</h3>
                    <h4>Levering Door Netbeheerder</h4>
                    <p>
                      Dit is de hoeveelheid elektriciteit die is geleverd door
                      de netbeheerder (in kWh).
                    </p>
                  </li>
                  <li>
                    <h3>ODN</h3>
                    <h4>Ontvangen Door Netbeheerder</h4>
                    <p>
                      Dit verwijst naar de hoeveelheid elektriciteit die aan het
                      elektriciteitsnetwerk is geleverd (in kWh).
                    </p>
                  </li>
                  <li>
                    <h3>BPM</h3>
                    <h4>Bruto Productie Meter</h4>
                    <p>
                      Dit verwijst naar de bruto productie van de PV installatie
                      (in kWh).
                    </p>
                  </li>
                </ul>
                <p>Waardes in deze kolommen moeten positief zijn.</p>
                <h2>Uitleg tijdkolom</h2>
                <ul>
                  <li>
                    De tijdstap moet de indeling YYYY-MM-DD HH:MM:SS gebruiken.
                  </li>
                  <li>
                    Tijdstappen moeten consistent zijn en een heel kalenderjaar
                    bestrijken.
                  </li>
                  <li>Er mag geen data ontbreken</li>
                  <li>
                    Als er geen tijdzone is aangegeven, wordt
                    <a
                      href="https://en.wikipedia.org/wiki/Coordinated_Universal_Time"
                      >UTC</a
                    >
                    aangenomen
                  </li>
                </ul>
              </div>
            </template>
          </BaseDrawer>
        </div>
        <div class="flex flex-col">
          <p class="font-medium">2. Upload de Excel</p>
          <p class="text-gray-400">Upload de ingevulde template</p>
        </div>
        <div
          v-for="(error, index) in wizardStore.excel.errorList"
          :key="index"
          class="relative flex flex-col gap-2 rounded-lg bg-danger-50 p-4"
        >
          <div class="flex flex-col gap-1">
            <div class="flex items-center gap-2 text-danger-500">
              <BaseIcon class="text-danger-500" name="information" />
              <p class="text-sm font-bold">{{ error.title }}</p>
            </div>
            <p class="text-xs">{{ error.content }}.</p>
          </div>
        </div>
        <!-- </template> -->
        <FileUpload
          :file-types="fileTypes"
          :max-file-size="maxFileSize"
          data-cy="file-upload"
          :init-file="pickedFile"
          :remove-to-upload="hasDataFromConfig"
          @file-picked="pickHandler"
          @file-removed="removeHandler"
        />
        <LoadingWrapper :loading="loading">
          <RadioButtonList
            v-if="pickedFile || hasDataFromConfig"
            v-model="wizardStore.excel.type"
            data-cy="excel-type"
            :items="dataOptions"
            variant="default"
            :all-disabled="hasDataFromConfig"
            title="3. Welke geüploade data wil je gaan gebruiken?"
            sub-title="Zie 'Uitleg template' voor welke kolommen gebruikt moeten worden."
            :helper-text="
              vuelidateErrors(
                wizardValidationStore.v$dataRules.excel.type,
                'Geüploade data type',
              )
            "
            :state-error="
              !!wizardValidationStore.v$dataRules.excel.type.$errors.length
            "
            @update:model-value="wizardValidationStore.v$dataRules.$validate()"
          />
        </LoadingWrapper>
        <div
          v-if="wizardStore.excel.validatedData"
          class="flex items-center gap-4 rounded-lg bg-primary-100 p-4"
        >
          <BaseIcon
            name="checkbox-circle"
            class="shrink-0 text-2xl text-primary-600"
          />
          <p>
            De geüploade data is klaar om te gebruiken. Ga verder naar de
            volgende stap.
          </p>
        </div>
      </template>
    </template>
    <template #summary>
      {{
        wizardStore.excel.validatedData
          ? `Bestand geüpload &bull;&nbsp;${wizardStore.excel.type}`
          : "Geen bestand geüpload"
      }}
    </template>
  </WizardStep>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { useRoute } from "vue-router"
import { fetchApi } from "@/services/bstApiService"
import { dataOptions, useWizardStore } from "@/stores/wizardStore.js"
import {
  BaseButton,
  BaseIcon,
  BaseDrawer,
  TextDrawerTitle,
  LoadingWrapper,
  RadioButtonList,
  CheckboxItem,
} from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"
import FileUpload from "@/components/FileUpload.vue"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import { useNotificationStore } from "@/stores/useNotificationStore"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"

defineProps({
  /**
   * Used to determine which step is currently active
   */
  isActive: {
    type: Boolean,
    required: true,
  },
  /**
   * For determining wether to show the finished checkmark and summary of the inputfields.
   */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to determine if the step is enabled or disabled
   */
  isEnabled: {
    type: Boolean,
    default: true,
  },
})

const route = useRoute()
const wizardValidationStore = useWizardValidationStore()
const notificationStore = useNotificationStore()
const wizardStore = useWizardStore()
const pickedFile = ref()
const fileTypes = ["xls", "xlsx"]
const maxFileSize = 30000000
const hasDataFromConfig = ref(
  wizardStore.excel.validatedData && route.name === "wizard-edit",
)
const description =
  "Upload een Excel document met jouw eigen data. Gebruik onze Excel template om de gegevens juist te uploaden."
const wizardStepRef = ref()
const loading = ref(false)
const loadingFailed = ref(false)
const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)

defineExpose({ getValidationState })

function pickHandler(file) {
  pickedFile.value = file
  if (loadingFailed.value) loadingFailed.value = false
}

function removeHandler() {
  pickedFile.value = null
  wizardStore.excel.validatedData = null
  wizardStore.excel.type = null
  wizardStore.excel.errorList = null
  if (loadingFailed.value) loadingFailed.value = false
  hasDataFromConfig.value = false
}

watch(
  () => wizardStore.useExcelData,
  (value) => {
    if (!value) removeHandler()
  },
)

watch(
  () => wizardStore.excel.type,
  (value) => {
    if (!value) return
    loading.value = true
    wizardStore.validateExcelFile(
      pickedFile.value,
      () => {
        loading.value = false
      },
      () => {
        loading.value = false
      },
      (error) => {
        loading.value = false
        loadingFailed.value = true
        notificationStore.pushError(
          "Valideren van het Excel bestand mislukt",
          `Probeer het later opnieuw (code: ${error.code}).`,
          "excel-validation-error",
        )
        wizardStore.excel.type = null
      },
    )
  },
)

function templateHandler() {
  fetchApi("/template/")
    .then(async (response) => {
      const a = document.createElement("a")
      a.href = URL.createObjectURL(await response.blob())
      a.download = "Repowered_quickscan_template.xlsx"
      a.click()
      notificationStore.pushToast(
        "Excel template wordt nu gedownload",
        null,
        "template-download",
      )
    })
    .catch((error) => {
      notificationStore.pushError(
        "Downloaden mislukt",
        `Probeer het later opnieuw (code: ${error.code}).`,
        "download-error",
      )
    })
}
</script>

<style lang="postcss" scoped>
.documentation h2 {
  @apply text-lg font-bold;
}

.documentation h3 {
  @apply font-medium;
}

.documentation h4 {
  @apply text-sm text-gray-500;
}

.documentation ol {
  @apply list-decimal pl-4;
}

.documentation ul {
  @apply list-disc pl-4;
}

.documentation a {
  @apply underline decoration-primary decoration-2 hover:text-primary;
}
</style>
