import { defineStore } from "pinia"
import { postApiJson, postApi } from "@/services/bstApiService"
import { excelToArray } from "@/services/excelService.js"
import { useDebounceFn } from "@vueuse/core"

export const operatorOptions = [
  { name: "Liander", value: "Liander" },
  { name: "Enexis", value: "Enexis" },
  { name: "Stedin", value: "Stedin" },
  { name: "Westland Infra", value: "Westland Infra" },
  { name: "Rendo", value: "Rendo" },
  { name: "Coteq", value: "Coteq" },
  { name: "Tennet", value: "Tennet" },
]

export const dataOptions = [
  { label: "LDN", value: "LDN", tooltip: "Levering Door Netbeheerder" },
  { label: "BPM", value: "BPM", tooltip: "Bruto Productie Meter" },
  {
    label: "ODN en LDN",
    value: "ODN, LDN",
    tooltip: "Ontvangen Door Netbeheerder en Levering Door Netbeheerder",
  },
  {
    label: "ODN, LDN en BPM",
    value: "ODN, LDN, BPM",
    tooltip:
      "Ontvangen Door Netbeheerder, Levering Door Netbeheerder en Bruto Productie Meter",
  },
]

export const orientationOptions = [
  { name: "Oost-west", value: "east_west" },
  { name: "Zuid", value: "south" },
]

export const batteryTypeOptions = [
  { name: "Lithium", value: "li" },
  { name: "Vanadium", value: "va" },
]

export const strategyOptions = [
  {
    tooltip: "/bst/wizard/financial-strategy",
    label: "Financiële optimalisatie",
    value: "financial_optimization",
  },
  {
    tooltip: "/bst/wizard/self-sufficiency",
    label: "Zelfvoorzienendheid",
    value: "self_sufficiency",
  },
]

export const yearOptions = [2023, 2022]

export const useWizardStore = defineStore("wizardStore", {
  /**
   * Variables marked as 'InputSelect item' are objects with structure
   * { name: string, value: string }, which should be accounted for when passing
   * data to the backend.
   */
  state: () => ({
    currentStep: 1,
    name: {
      newName: null,
    },
    simulation: {
      name: null,
      validName: null,
      type: "",
      year: 2023,
    },
    grid: {
      importBound: null,
      exportBound: null,
      operator: null, // InputSelect item
    },
    excel: {
      validatedData: null,
      type: null,
      errorList: null,
    },
    demand: {
      profile: null, // InputSelect item
      total: null,
    },
    solarParks: [], // 'orientation' is an InputSelect item
    battery: {
      capacity: null,
      power: null,
      type: null, // InputSelect item
      energyInvestDiscount: 0.1174,
      investment: null,
      investmentIncludesInstallation: true,
      optimizationFeeFactor: 0,
    },
    strategy: null,
    useExcelData: false,
  }),
  getters: {
    /**
     * Prepare the data to be sent to the backend for the intermediate feedback.
     */
    intermediateInput: (state) => {
      const obj = {
        case_name: state.simulation.name,
        year: state.simulation.year,
        quickscan_type: state.simulation.type,
        grid: {
          import_bound: state.grid.importBound,
          export_bound: state.grid.exportBound,
          operator: state.grid.operator.value,
        },
      }
      if (state.useExcelData) {
        obj.demand_df = state.excel.validatedData
        obj.BPM = state.excel.type.includes("BPM")
        obj.ODN = state.excel.type.includes("ODN")
        obj.LDN = state.excel.type.includes("LDN")
      }
      if (state.demand.profile) {
        obj.farmer_type = state.demand.profile.value
        obj.total_demand = state.demand.total
      }
      if (state.solarParks.length) {
        obj.solar_parks = state.solarParks.map((park) => ({
          orientation: park.orientation.value,
          AC: park.ac,
          DC: park.dc,
        }))
      }
      return obj
    },
    /**
     * Prepare the data to be sent to the backend for submitting a new
     * simulation.
     */
    simulationInput: (state) => ({
      ...state.intermediateInput,
      battery: {
        capacity: state.battery.capacity,
        power: state.battery.power,
        type: state.battery.type.value,
        costs: {
          capex: state.battery.investment || 0,
          capex_includes_installation:
            state.battery.investmentIncludesInstallation,
          EIA: state.battery.energyInvestDiscount,
          optimization_fee_factor: state.battery.optimizationFeeFactor,
        },
      },
      strategy: state.strategy,
      demand_type: "none", // TODO: Remove when no longe required
    }),
  },
  actions: {
    /**
     * Manage entries in the 'solaPaks' state variable
     */
    addSolarPark() {
      this.solarParks.push({ orientation: null, ac: "", dc: "" })
    },
    removeSolarPark(index) {
      this.solarParks.splice(index, 1)
    },
    /**
     * Besides validating the Excel filein the backend, this also stores the
     * validated data returned.
     */
    async validateExcelFile(file, onValid, onInvalid, onError) {
      this.excel.validatedData = null
      const body = {
        excel_data: await excelToArray(file),
        BPM: this.excel.type.includes("BPM"),
        ODN: this.excel.type.includes("ODN"),
        LDN: this.excel.type.includes("LDN"),
      }
      postApiJson("/is_valid_excel/", body)
        .then((data) => {
          if (data.valid) {
            this.excel.validatedData = data.excel_json
            if (onValid) onValid(data)
          } else {
            this.excel.errorList = data.error_list
            if (onInvalid) onInvalid(data)
          }
        })
        .catch((error) => {
          if (onError) onError(error)
        })
    },
    validateCaseName: useDebounceFn(async function (caseName) {
      if (caseName?.length >= 3) {
        postApi(`/is_valid_casename/?case_name=${caseName}`).then(
          (response) => {
            switch (response.status) {
              case 200:
                this.simulation.validName = true
                break
              default:
                this.simulation.validName = false
                break
            }
          },
        )
      }
    }, 300), // 300ms validation delay
    /**
     * Load a simulation configuration into the store's state.
     */
    loadConfiguration(config) {
      // Wizard state variables that are always present
      const newState = {
        simulation: {
          name: config.case_name,
          type: config.quickscan_type,
        },
        year: config.year,
        grid: {
          importBound: config.grid.import_bound,
          exportBound: config.grid.export_bound,
          operator: operatorOptions.find(
            (option) => option.value === config.grid.operator,
          ),
        },
        battery: {
          capacity: config.battery.capacity,
          power: config.battery.power,
          type: batteryTypeOptions.find(
            (option) => option.value === config.battery.type,
          ),
          energyInvestDiscount: config.battery.costs.EIA,
          investment: config.battery.costs.capex,
          investmentIncludesInstallation:
            config.battery.costs.capex_includes_installation,
        },
        strategy: config.strategy,
        useExcelData: config.BPM || config.ODN || config.LDN,
      }
      // Optional wizard state variables
      if (newState.useExcelData) {
        const flags = { ODN: config.ODN, LDN: config.LDN, BPM: config.BPM }
        newState.excel = {
          type: Object.keys(flags)
            .filter((key) => flags[key])
            .join(", "),
          validatedData: config.demand_df,
          errorList: null,
        }
      }
      if (config.farmer_type) {
        newState.demand = {
          profile: {
            name: config.farmer_type,
            value: config.farmer_type,
          },
          total: config.total_demand,
        }
      }
      if (config.solar_parks) {
        newState.solarParks = config.solar_parks.map((park) => ({
          orientation: orientationOptions.find(
            (option) => option.value === park.orientation,
          ),
          ac: park.AC,
          dc: park.DC,
        }))
      }
      this.$patch(newState)
    },
  },
})
