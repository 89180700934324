<template>
  <WizardStep
    ref="wizardStepRef"
    :description="description"
    :is-active="isActive"
    :is-complete="!wizardValidationStore.consumptionStepInvalid"
    :is-enabled="isEnabled"
    :validation-state="wizardValidationStore.v$consumptionRules"
    label="Stap 4"
    title="Verbruik handmatig toevoegen"
    @confirm-btn="wizardValidationStore.v$consumptionRules.$validate()"
  >
    <template #content>
      <div class="flex flex-col gap-1">
        <InputSelect
          v-model="wizardStore.demand.profile"
          data-cy="demand-profile"
          :helper-text="
            vuelidateErrors(
              wizardValidationStore.v$consumptionRules.demand.profile,
              'Soort bedrijf',
            )
          "
          :options="defaultProfiles"
          :state-error="
            !!wizardValidationStore.v$consumptionRules.demand.profile.$errors
              .length
          "
          label="name"
          placeholder="Kies het soort verbruik"
          select-title="Soort bedrijf"
          track-by="name"
        />
      </div>

      <InputField
        v-model="wizardStore.demand.total"
        data-cy="demand-total"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$consumptionRules.demand.total,
            'Afnamecapaciteit',
          )
        "
        :state-error="
          !!wizardValidationStore.v$consumptionRules.demand.total.$errors.length
        "
        label="Totaal jaarlijks verbruik"
        placeholder="Bijv. 10.000"
        type="number"
        @update:model-value="
          wizardValidationStore.v$consumptionRules.demand.total.$touch()
        "
      >
        <template #addonRight> kWh </template>
      </InputField>
    </template>
    <template #summary>
      {{ wizardStore.demand.profile.value }}
      &bull;&nbsp;{{ formatNumber(wizardStore.demand.total, 0) }}&nbsp;kWh
    </template>
  </WizardStep>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { formatNumber } from "@/services/formatterService"
import { useWizardStore } from "@/stores/wizardStore"
import { InputField, InputSelect } from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"
import { fetchApiJson } from "@/services/bstApiService"
import { watchOnce } from "@vueuse/core"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
const wizardValidationStore = useWizardValidationStore()

const props = defineProps({
  /**
   * Used to determine which step is currently active
   */
  isActive: {
    type: Boolean,
    required: true,
  },
  /**
   * For determining wether to show the finished checkmark and summary of the inputfields.
   */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to determine if the step is enabled or disabled
   */
  isEnabled: {
    type: Boolean,
    default: true,
  },
})

const wizardStore = useWizardStore()
const description =
  "Vul hier handmatig uw gebruik in, zodat we uw verbruik op kwartierbasis kunnen simuleren."
const wizardStepRef = ref()

const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)

defineExpose({ getValidationState })

/**
 * Default profiles for the select input.
 * Will be fetched from the API once when the step is initially activated.
 */
const defaultProfiles = ref([])
watchOnce(
  () => props.isActive,
  async (isActive) => {
    if (!isActive) return
    const response = await fetchApiJson("/default_profiles/")
    defaultProfiles.value =
      response.map((item) => ({
        name: item,
        value: item,
      })) || []
  },
)

watch(
  () => props.isEnabled,
  (enabled) => {
    if (!enabled) {
      wizardStore.demand.profile = null
      wizardStore.demand.total = null
      wizardValidationStore.v$consumptionRules.$reset()
    }
  },
  { immediate: true },
)
</script>
