<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 5"
    title="Opwek handmatig toevoegen"
    :description="description"
    :is-active="isActive"
    :is-enabled="isEnabled"
    :is-complete="!wizardValidationStore.generateStepInvalid"
    :validation-state="wizardValidationStore.v$generateRules"
    @confirm-btn="wizardValidationStore.v$generateRules.$validate()"
  >
    <template #content>
      <div
        v-if="dcSum > 0 || acSum > 0"
        class="rounded-lg bg-primary-50 p-2 text-sm"
      >
        <p>Totaal opgesteld vermogen PV-installatie:</p>
        <p class="font-bold">
          DC: {{ formatNumber(dcSum, 0) }} kWp, AC:
          {{ formatNumber(acSum, 0) }} kWp
        </p>
      </div>
      <form autocomplete="off" class="flex flex-col gap-4">
        <div
          v-for="(park, index) in wizardStore.solarParks"
          :key="index"
          class="flex flex-col gap-4 rounded-lg border border-gray-200 p-4"
        >
          <div class="flex flex-col gap-1">
            <InputSelect
              v-model="park.orientation"
              :data-cy="`solar-orientation-${index}`"
              :allow-empty="true"
              :helper-text="
                vuelidateErrors(
                  wizardValidationStore.v$generateRules.solarParks[index]
                    ?.orientation,
                  'Orientatie',
                )
              "
              :options="orientationOptions"
              :state-error="
                !!vuelidateErrors(
                  wizardValidationStore.v$generateRules.solarParks[index]
                    ?.orientation,
                )
              "
              label="name"
              placeholder="Kies de orientatie"
              select-title="Orientatie PV-installatie"
              track-by="name"
            />
          </div>
          <div>
            <InputField
              v-model="park.dc"
              :data-cy="`solar-dc-${index}`"
              :helper-text="
                vuelidateErrors(
                  wizardValidationStore.v$generateRules.solarParks[index]?.dc,
                  'Opgesteld vermogen PV-installatie',
                )
              "
              :state-error="
                !!wizardValidationStore.v$generateRules.solarParks[index]?.dc
                  .$errors.length
              "
              label="Opgesteld vermogen PV-installatie (DC)"
              placeholder="Bijv. 10.000"
              type="number"
              @update:model-value="
                wizardValidationStore.v$generateRules.solarParks[
                  index
                ].dc.$touch()
              "
            >
              <template #addonRight> kWp </template>
            </InputField>
          </div>
          <div>
            <InputField
              v-model="park.ac"
              :data-cy="`solar-ac-${index}`"
              :helper-text="
                vuelidateErrors(
                  wizardValidationStore.v$generateRules.solarParks[index]?.ac,
                  'Opgesteld vermogen omvormer',
                )
              "
              :state-error="
                !!wizardValidationStore.v$generateRules.solarParks[index]?.ac
                  .$errors.length
              "
              autocomplete="false"
              label="Opgesteld vermogen Omvormer (AC)"
              placeholder="Bijv. 10.000"
              type="number"
              @update:model-value="
                wizardValidationStore.v$generateRules.solarParks[
                  index
                ].ac.$touch()
              "
            >
              <template #addonRight> kWp </template>
            </InputField>
          </div>
          <div v-if="index !== 0">
            <!-- @click function removes the element with the corresponding index -->
            <BaseButton
              class="text-danger hover:text-white"
              color="subtle"
              @click="wizardStore.removeSolarPark(index)"
            >
              <BaseIcon name="delete-bin" class="mr-2 text-danger" />
              <p class="text-sm text-danger">Verwijder</p>
            </BaseButton>
          </div>
        </div>
      </form>
      <BaseButton
        class="h-[32px] w-[197px] border border-primary"
        color="minimal-primary"
        @click="wizardStore.addSolarPark()"
      >
        <BaseIcon name="add" class="mr-2" />
        <p class="text-sm">Voeg orientatie toe</p>
      </BaseButton>
    </template>
    <template #summary>
      AC:&nbsp;{{ formatNumber(acSum, 0) }}&nbsp;kWp &bull;&nbsp;DC:&nbsp;{{
        formatNumber(dcSum, 0)
      }}&nbsp;kWp
    </template>
  </WizardStep>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { formatNumber } from "@/services/formatterService"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"
import { orientationOptions, useWizardStore } from "@/stores/wizardStore"
import {
  InputField,
  InputSelect,
  BaseButton,
  BaseIcon,
} from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"

const props = defineProps({
  /**
   * Used to determine which step is currently active
   */
  isActive: {
    type: Boolean,
    required: true,
  },
  /**
   * For determining wether to show the finished checkmark and summary of the inputfields.
   */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /**
   * Used to determine if the step is enabled or disabled
   */
  isEnabled: {
    type: Boolean,
    default: true,
  },
})

const wizardValidationStore = useWizardValidationStore()
const wizardStore = useWizardStore()
const description =
  "Vul hier handmatig uw opwek in, zoda we uw opwek op kwartierbasis kunnen simuleren."
const wizardStepRef = ref()

const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)

defineExpose({ getValidationState })

const dcSum = computed(() => {
  return wizardStore.solarParks.reduce((acc, park) => {
    return acc + park.dc
  }, 0)
})

const acSum = computed(() => {
  return wizardStore.solarParks.reduce((acc, park) => {
    return acc + park.ac
  }, 0)
})

watch(
  () => props.isEnabled,
  (enabled) => {
    if (enabled) {
      if (!wizardStore.solarParks.length) {
        wizardStore.addSolarPark()
      }
    } else {
      wizardStore.solarParks = []
      wizardValidationStore.v$generateRules.$reset()
    }
  },
  { immediate: true },
)
</script>
