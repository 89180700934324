import { defineStore } from "pinia"
import { fetchApiJson } from "@/services/bstApiService.js"
import { useRouter } from "vue-router"

export const useDemoStore = defineStore("demoStore", {
  state: () => ({
    active: false,
    ended: true,
    date_ends: "2050-01-01T00:00:00.000Z",
    simulation_quotum_exceeded: false,
  }),
  actions: {
    getDemoConfiguration(onSuccess, onFail) {
      const router = useRouter()
      fetchApiJson("/demo/")
        .then((res) => {
          this.active = true
          this.date_ends = res.date_ends
          this.simulation_quotum_exceeded = res.simulation_quotum_exceeded
          this.ended = res.ended
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (error.code === 402) {
            this.active = true
            this.ended = true
            router.push({ name: "demo-expired" })
          } else if (error.code === 404) {
            this.active = false
            this.ended = false
          } else {
            if (onFail) onFail(error)
          }
        })
    },
  },
})
