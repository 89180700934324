<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 6"
    title="Berekening huidige situatie"
    :description="description"
    :is-active="isActive"
    :is-enabled="isEnabled"
    :is-complete="!wizardValidationStore.calculationStepInvalid"
    :validation-state="wizardValidationStore.v$calculationRules"
    :disabled-next-button="!newSimulationStore.dataframes"
  >
    <template #content>
      <LoadingWrapper
        :loading="newSimulationStore.loadingIntermediate"
        :error="newSimulationStore.errorIntermediate"
      >
        <BaseCard v-for="(table, index) in tables" :key="index" inner>
          <BaseTable v-bind="table" class="calculation-table">
            <template #title>
              {{ table.columns.title.label }}
              <WikiTooltipComponent
                v-if="table.columns.title.tooltip"
                :path="table.columns.title.tooltip"
              />
            </template>
            <template #title-total="{ row }">
              <span class="font-medium">{{ row.title }}</span>
            </template>
            <template #value-total="{ row }">
              <span class="font-medium">{{ row.value }}</span>
            </template>
          </BaseTable>
        </BaseCard>
      </LoadingWrapper>
    </template>

    <template #nextButton>
      Gegevens kloppen, ga verder
      <BaseIcon class="ml-2" name="arrow-right" :filled="false" />
    </template>
  </WizardStep>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { formatNumber } from "@/services/formatterService"
import { useWizardStore } from "@/stores/wizardStore"
import useNewSimulationStore from "@/stores/newSimulationStore.js"
import {
  LoadingWrapper,
  BaseIcon,
  BaseTable,
  BaseCard,
} from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const props = defineProps({
  /*
    Used to determine which step is currently active
    */
  isActive: {
    type: Boolean,
    required: true,
  },
  /*
    For determining wether to show the finished checkmark and summary of the inputfields.
    TODO: This step will probably be complete upon receiving a succesful response with a estimate
    TODO: Create a relevant function
    */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /*
    Used to determine if the step is enabled
    */
  isEnabled: {
    type: Boolean,
    default: true,
  },
})

const wizardValidationStore = useWizardValidationStore()
const wizardStore = useWizardStore()
const newSimulationStore = useNewSimulationStore()
const wizardStepRef = ref()
const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)
const description = computed(() => {
  return `Berekening van de huidige energetische situatie en een inschatting van de energierekening met een dynamisch contract gebaseerd op de marktprijzen van ${wizardStore.simulation.year}.`
})
const tables = computed(() => {
  if (!newSimulationStore.dataframes) return []
  const titles = {
    data_production: {
      title: "Opwek",
      format: "energy",
      tooltip: "/bst/wizard/feed-in",
    },
    data_demand: {
      title: "Verbruik",
      format: "energy",
      tooltip: "/bst/wizard/consumption",
    },
    data_costs: {
      title: "Kosten",
      format: "finance",
      tooltip: `/bst/wizard/costs`,
    },
    data_yields: {
      title: "Baten",
      format: "finance",
      tooltip: `/bst/wizard/income`,
    },
    data_result: {
      title: "Totaal",
      format: "finance",
      tooltip: `/bst/wizard/total`,
    },
    data_availability: {
      title: "Beschikbaarheid van de aansluiting voor de batterij",
      format: "percentage",
      tooltip: ``,
    },
  }
  // Map each dataframe to table component inputs
  const tables = []
  Object.entries(newSimulationStore.dataframes).forEach(([df, values]) => {
    if (df in titles) {
      const rows = Object.entries(values).map(([key, value], index) => {
        return {
          title: key,
          value:
            titles[df].format === "energy"
              ? `${formatNumber(value / 1000, 0)} MWh`
              : titles[df].format === "percentage"
                ? `${formatNumber(value, 0)}%`
                : `€ ${formatNumber(value, 0)}`,
          _id:
            df !== "data_availability" &&
            index === Object.keys(values).length - 1
              ? "total"
              : null,
        }
      })
      tables.push({
        columns: {
          title: { label: titles[df].title, tooltip: titles[df].tooltip },
          value: { classes: "text-right" },
        },
        rows,
      })
    }
  })
  return tables
})

defineExpose({ getValidationState })
watch(
  [
    () => wizardStore.simulation,
    () => wizardStore.grid,
    () => wizardStore.excel,
    () => wizardStore.demand,
    () => wizardStore.solarParks,
  ],
  () => {
    if (newSimulationStore.token) {
      newSimulationStore.dataframes = null
    }
  },
  { deep: true },
)

watch(
  () => props.isActive,
  (active) => {
    if (
      active &&
      (!newSimulationStore.token || !newSimulationStore.dataframes)
    ) {
      newSimulationStore.submitIntermediate(null, (error) => {
        console.error(
          `Error submitting intermediate data (code: ${error.code})`,
        )
      })
    }
  },
)
</script>

<style lang="postcss" scoped>
.calculation-table {
  ::v-deep(th) {
    @apply bg-transparent;
    svg {
      @apply relative -top-1/2;
    }
  }
}
</style>
