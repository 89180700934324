<template>
  <WizardStep
    ref="wizardStepRef"
    label="Stap 1"
    title="Simulatie aanmaken"
    :hide-next-button="false"
    :hide-back-button="true"
    :is-active="isActive"
    :is-enabled="isEnabled"
    :is-complete="!wizardValidationStore.simulationStepInvalid"
    :validation-state="wizardValidationStore.v$simulationRules"
    @confirm-btn="wizardValidationStore.v$simulationRules.$validate()"
  >
    <template #content>
      <div class="flex flex-col gap-2">
        <InputField
          v-model="wizardStore.simulation.name"
          data-cy="simulation-name"
          placeholder="Naam van simulatie"
          label="Naam nieuwe simulatie"
          :helper-text="returnHelperText()"
          :state-error="
            !!wizardValidationStore.v$simulationRules.simulation.name.$errors
              .length || wizardStore.simulation.validName === false
          "
          @update:model-value="
            wizardValidationStore.v$simulationRules.simulation.name.$touch()
          "
          @input="wizardStore.validateCaseName(wizardStore.simulation.name)"
        />
      </div>

      <InputSelect
        v-model="wizardStore.simulation.year"
        data-cy="simulation-year"
        :options="yearOptions"
        placeholder="Selecteer marktjaar"
        select-title="Welk marktjaar wil je gebruiken voor de simulatie?"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$simulationRules.simulation.year,
            'Marktjaar',
          )
        "
        :state-error="
          !!wizardValidationStore.v$simulationRules.simulation.year.$errors
            .length
        "
        @update:model-value="
          wizardValidationStore.v$simulationRules.simulation.year.$touch()
        "
      />

      <RadioButtonList
        v-model="wizardStore.simulation.type"
        variant="default"
        data-cy="simulation-type"
        title="Waarvoor wil je de simulatie doen?"
        sub-title="Beschrijf hier je casus"
        :items="simulationTypes"
        :helper-text="
          vuelidateErrors(
            wizardValidationStore.v$simulationRules.simulation.type,
            'Type simulatie',
          )
        "
        :state-error="
          !!wizardValidationStore.v$simulationRules.simulation.type.$errors
            .length
        "
      />
    </template>
    <template #nextButton>Aanmaken</template>
    <template #summary>
      {{ wizardStore.simulation.name }} &bull;&nbsp;{{
        wizardStore.simulation.year
      }}
      &bull;&nbsp;{{ wizardStore.simulation.type }}
    </template>
  </WizardStep>
</template>

<script setup>
import { ref, computed } from "vue"
import { useWizardStore, yearOptions } from "@/stores/wizardStore"
import {
  InputField,
  InputSelect,
  RadioButtonList,
} from "@repowerednl/ui-component-library"
import WizardStep from "@/components/wizard/WizardStep.vue"
import { useWizardValidationStore } from "@/stores/useWizardValidationStore"
import { vuelidateErrors } from "@/helpers/vuelidate-errors"
import { validateFeedback } from "@/services/validationService"
defineProps({
  /*
  Used to determine which step is currently active
  */
  isActive: {
    type: Boolean,
    required: true,
  },
  /*
  For determining wether to show the finished checkmark and summary of the inputfields.
  */
  isComplete: {
    type: Boolean,
    default: false,
  },
  /*
  Used to determine if the step is enabled
  */
  isEnabled: {
    type: Boolean,
    default: true,
  },
})

const wizardValidationStore = useWizardValidationStore()
const wizardStore = useWizardStore()
const simulationTypes = ref([
  { label: "Opwek en verbruik", value: "opwek, verbruik" },
  { label: "Alleen opwek", value: "opwek" },
  { label: "Alleen verbruik", value: "verbruik" },
])
const wizardStepRef = ref()

const getValidationState = computed(
  () => wizardStepRef.value?.getValidationState,
)

function returnHelperText() {
  return validateFeedback(
    vuelidateErrors(
      wizardValidationStore.v$simulationRules.simulation.name,
      "Naam",
    ),
    wizardStore.simulation.validName,
    `Let op: ${wizardStore.simulation.name} is al in gebruik`,
  )
}

defineExpose({ getValidationState })
</script>
