import { defineStore } from "pinia"
import { useWizardStore } from "@/stores/wizardStore.js"
import { fetchApiJson, postApiJson } from "@/services/bstApiService"

export default defineStore("newSimulationStore", {
  state: () => ({
    loadingIntermediate: false,
    errorIntermediate: false,
    loadingSimulation: false,
    loadingConfiguration: false,
    token: null,
    dataframes: null,
  }),
  actions: {
    submitIntermediate(onSuccess, onFail) {
      const body = { ...useWizardStore().intermediateInput }
      if (this.token) body.simulation_token = this.token
      this.loadingIntermediate = true
      postApiJson("/intermediate_feedback/", body)
        .then((data) => {
          this.token = data.simulation_token
          this.dataframes = {
            ...JSON.parse(data.current_energetic_df),
            ...JSON.parse(data.current_financial_df),
            data_availability: {
              "Beschikbaarheid van de aansluiting voor levering":
                data.offtake_availability,
              "Beschikbaarheid van de aansluiting voor teruglevering":
                data.inflow_availability,
            },
          }
          this.errorIntermediate = false
          if (onSuccess) onSuccess(data)
        })
        .catch((error) => {
          this.errorIntermediate = true
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loadingIntermediate = false
        })
    },
    submitSimulation(onSuccess, onNoToken, onFail) {
      if (!this.token) {
        if (onNoToken) onNoToken()
        return
      }
      const body = {
        ...useWizardStore().simulationInput,
        simulation_token: this.token,
      }
      this.loadingSimulation = true
      postApiJson("/simulation/", body)
        .then((data) => {
          if (onSuccess) onSuccess(data)
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loadingSimulation = false
        })
    },
    getConfiguration(token, onSuccess, onNotFound, onFail) {
      this.loadingConfiguration = true
      fetchApiJson(`/configuration/?simulation_token=${token}`)
        .then((data) => {
          const { simulation_token } = data
          this.token = simulation_token
          if (onSuccess) onSuccess(data)
        })
        .catch((error) => {
          if (error.code === 404 && onNotFound) {
            onNotFound()
            return
          }
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loadingConfiguration = false
        })
    },
  },
})
