import { defineStore } from "pinia"
import {
  fetchApi,
  fetchApiJson,
  postApiJson,
} from "@/services/bstApiService.js"

export default defineStore("simulationsStore", {
  state: () => ({
    loading: false,
    loadingFailed: false,
    simulations: [],
  }),
  actions: {
    loadSimulations(onSuccess, onFail) {
      this.loading = true
      fetchApiJson("/cases/")
        .then((data) => {
          this.simulations = data
          this.loadingFailed = false
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          this.loadingFailed = true
          if (onFail) onFail(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    delete(simulationToken, onSuccess, onFail) {
      postApiJson(
        `/delete_case/?simulation_token=${simulationToken}`,
        {},
        { method: "DELETE" },
      )
        .then(() => {
          this.loadSimulations()
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
    duplicate(simulationToken, onSuccess, onFail) {
      postApiJson(`/duplicate_case/?simulation_token=${simulationToken}`)
        .then(() => {
          this.loadSimulations()
          if (onSuccess) onSuccess()
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
    changeCaseName(simToken, newName, onSuccess, onFail) {
      fetchApi(
        `/change_simulation_name/?simulation_token=${simToken}&new_simulation_name=${newName}`,
        { method: "PUT" },
      )
        .then((data) => {
          this.loadSimulations()
          if (onSuccess) onSuccess()
          return data
        })
        .catch((error) => {
          if (onFail) onFail(error)
        })
    },
  },
})
