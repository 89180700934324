/**
 * Converts a numeric value to a formatted currency string.
 *
 * @param {number} value - The value to be converted to currency.
 * @return {string} - The formatted currency string.
 */
export function toCurrency(value) {
  if (!value) return "-"
  return value.toLocaleString("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

/**
 * Formats a number as a currency with digits.
 *
 * If the value is false, it returns '€ 0-,'.
 * Otherwise, it uses the "nl-NL" locale to format the value as a currency in Euros (EUR),
 * with a minimum of 2 fractional digits.
 *
 * @param {number} value - The number to format as currency.
 * @returns {string} The input value formatted as a currency with digits.
 */
export function toCurrencyWithDigits(value) {
  if (!value) return "-"
  return value.toLocaleString("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  })
}

/**
 * Converts a numeric value to a decimal representation with specified number of decimal places.
 *
 * @param {number} value - The numeric value to be converted.
 * @param {number} decimals - The number of decimal places to be displayed.
 * @returns {string} - The decimal representation of the value with specified decimal places.
 */
export function toDecimal(value, decimals) {
  return value.toLocaleString("nl-NL", {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  })
}
