export default {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value()
      }
    }
    // Set capture to true to fire the event in the outermost element first
    addEventListener("click", el.clickOutsideEvent, true)
  },
  unmounted(el) {
    removeEventListener("click", el.clickOutsideEvent)
  },
}
