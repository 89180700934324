import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import "./index.css"
import "vue-multiselect/dist/vue-multiselect.css"
import "@repowerednl/ui-component-library/style.css"
import Chart from "chart.js/auto"
import { fontFamily } from "@/services/themeService.js"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import * as Sentry from "@sentry/vue"

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://44ebfc2975f719aea374f5045994166d@o4504639387467776.ingest.us.sentry.io/4507221190180864",
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to a lower rate in production.
  tracePropagationTargets: ["localhost", /^\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

Chart.defaults.font.family = fontFamily
Chart.defaults.font.weight = 500

app.use(router).use(pinia).mount("#app")
