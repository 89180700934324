import useUserStore from "@/stores/userStore"

const BASE_URL = `${import.meta.env.VITE_API_URL}/api`

export function fetchApi(path, options, noRetry) {
  const url = `${BASE_URL}${path}`
  const { accessToken, refreshToken } = useUserStore()
  const isNewTokenRequest = path === "/new_token/"
  const token = isNewTokenRequest ? refreshToken : accessToken
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    ...options,
  }).then((response) => {
    // Refresh access token if it's expired
    if (response.status === 401 && !noRetry && !isNewTokenRequest) {
      return useUserStore().refreshAccessToken(
        () => fetchApi(path, options, true),
        () => response,
      )
    }
    return response
  })
}

export function fetchApiCatch(path, options, toJSON = false) {
  const fetchReturn = fetchApi(path, options)
  return fetchReturn
    .then((response) => {
      if (response.status === 200) {
        return toJSON ? response.json() : response
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
    .catch((error) => {
      console.error(`Failed to fetch from API: '${path}' (code: ${error.code})`)
      throw error
    })
}

export function fetchApiJson(path, options) {
  return fetchApiCatch(path, options, true)
}

export function postApi(path, body, options) {
  return fetchApi(path, {
    method: "POST",
    credentials: "same-origin",
    body: JSON.stringify(body),
    ...options,
  })
}

export function postApiCatch(path, body, options, toJSON = false) {
  return postApi(path, body, options)
    .then((response) => {
      if (response.status === 200) {
        return toJSON ? response.json() : response
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
    .catch((error) => {
      console.error(`Failed to post to API: '${path}' (code: ${error.code})`)
      throw error
    })
}

export function postApiJson(path, body, options) {
  return postApiCatch(path, body, options, true)
}
