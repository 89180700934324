import { defineStore } from "pinia"
import { ref } from "vue"

import { fetchApiJson } from "@/services/bstApiService"

export const useResultsStore = defineStore("resultsStore", () => {
  const results = ref(null)
  const assumptions = ref(null)

  const loading = ref(false)
  const loadingFailed = ref(false)

  async function getResult(id) {
    loading.value = true
    await fetchApiJson(`/complete_feedback/?simulation_token=${id}`)
      .then((data) => {
        results.value = data
        loadingFailed.value = false
      })
      .catch(() => {
        loadingFailed.value = true
      })
    await getAssumption(id)

    loading.value = false
  }

  async function getAssumption(id) {
    await fetchApiJson(`/assumptions/?simulation_token=${id}`)
      .then((data) => {
        assumptions.value = data
      })
      .catch(() => {
        loadingFailed.value = true
      })
  }

  return {
    results,
    assumptions,
    loading,
    loadingFailed,
    getResult,
  }
})
