<template>
  <div
    class="mx-auto mt-6 flex max-w-[480px] flex-col items-center gap-4 md:w-[480px]"
  >
    <BaseCard class="flex w-full flex-col gap-4 p-6">
      <TextTitle>{{ isWelcome ? "Welkom" : "Wachtwoord vergeten" }}</TextTitle>
      <!-- Message if email has been sent -->
      <template v-if="emailSent">
        <div class="flex items-center gap-4 rounded-lg bg-primary-100 p-4">
          <BaseIcon
            name="checkbox-circle"
            class="shrink-0 text-2xl text-primary-600"
          />
          <p>
            Als het e-mailadres bekend is, ontvang je binnen enkele minuten een
            e-mail met een nieuw wachtwoord.
          </p>
        </div>
        <p>Je wordt automatisch doorgestuurd naar het inlogscherm.</p>
      </template>
      <!-- Email form -->
      <template v-else>
        <p>
          Vul hier het e-mailadres in waarmee je bent geregistreerd. Je ontvangt
          binnen enkele minuten een e-mail met een nieuw wachtwoord.
        </p>
        <form class="flex w-full flex-col gap-4" @submit.prevent>
          <InputField
            v-model="inputEmail"
            label="E-mailadres"
            :helper-text="
              showValidation && !validEmail
                ? 'Vul een geldig e-mailadres in'
                : null
            "
            :state-error="showValidation && !validEmail"
          />
          <BaseButton color="primary" class="w-fit" @click="onSend">
            Verstuur
          </BaseButton>
        </form>
      </template>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, computed, onUnmounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { postApiCatch } from "@/services/bstApiService.js"
import {
  BaseCard,
  TextTitle,
  InputField,
  BaseButton,
  BaseIcon,
} from "@repowerednl/ui-component-library"
import { useNotificationStore } from "@/stores/useNotificationStore.js"

const notificationStore = useNotificationStore()
const route = useRoute()
const router = useRouter()
const inputEmail = ref("")
const showValidation = ref(false)
const emailSent = ref(false)
let timer = null

const isWelcome = computed(() => {
  return route.path.includes("welcome")
})

const validEmail = computed(() => {
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/
  return inputEmail.value.match(emailPattern)
})

function onSendSuccess() {
  emailSent.value = true
  timer = setTimeout(() => {
    router.replace({ name: "login" })
  }, 4000)
}

function onSendFail(error) {
  notificationStore.pushError(
    "De e-mail kon niet worden verstuurd",
    `Probeer het later opnieuw (code: ${error.code}).`,
    "email-error",
  )
}

function onSend() {
  if (!validEmail.value) {
    showValidation.value = true
    return
  }
  postApiCatch(`/forgot_password_v2/?email=${inputEmail.value}`)
    .then(() => {
      onSendSuccess()
    })
    .catch((error) => {
      if (error.code === 404) {
        // Hide the fact that the email address is not found to prevent abuse
        // To do: have the backend return 200 in both cases
        onSendSuccess()
      } else {
        onSendFail(error)
      }
    })
}

onUnmounted(() => {
  clearTimeout(timer)
})
</script>
